<template>
  <div class="bg-black w-full h-full overflow-hidden">
    <div class="grid place-items-center w-full h-full" v-show="stage === 'start'">
      <div class="flex flex-col">
        <template v-if="showScreenError">
          <h3 class="text-3xl text-red-500 animate-pulse mb-2">NO,OPEN ON YOUR MAC MATT</h3>
        </template>
        <template v-else>
          <input @keydown.enter="checkPin" autofocus type="text" v-model="pin" class="text-2xl text-black text-center" />
          <button class="mt-4" @click.prevent="checkPin">Submit</button>
        </template>
        <template v-if="showScreenError">
          <h3 class="text-xl text-red-500 animate-pulse mt-2">YOU DONT GET THE SAME EXPERIENCE DUE TO MOBILE RESTRICTIONS</h3>
        </template>
      </div>
    </div>
    <div class="grid place-items-center w-full h-full" v-show="stage === 'headphones'">
      <button class="cursor-pointer" @click.prevent="start">Headphones on?</button>
    </div>
    <div class="grid place-items-center w-full h-full relative" v-show="stage === 'show'">
      <video src="/background_video.mp4" autoplay muted loop class="absolute w-full h-full object-cover"></video>
      <VTypical
          v-if="stage === 'show'"
          class="blink glitch text-3xl font-bold text-white/75 uppercase"
          :steps="steps"
          :wrapper="'h1'"
      ></VTypical>
      <template v-for="(image, index) in images" :key="index">
        <img v-show="image.show" :src="image.src" class="absolute w-full h-full object-cover top-0 left-0 z-20" :style="image.style"  alt="image"/>
      </template>

      <div v-show="showMain" class="absolute w-screen h-screen bg-black ">
        <div class="w-full h-full grid place-items-center p-4">
          <svg class="w-2/3 mx-auto" style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision" version="1.1" viewBox="0 0 12 4" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><title>The Prodigy logo (2009)</title><metadata><rdf:RDF><cc:Work rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title>The Prodigy logo (2009)</dc:title></cc:Work></rdf:RDF></metadata>
            <polygon class="fil0" points="10.03 0.6716 9.935 0.6724 9.952 0.701"/>
            <polygon class="fil1" points="6.332 0.992 6.289 1.009 6.318 1.009 6.318 1.038"/>

            <polygon class="fil1" points="4.34 1.52 4.237 1.536 4.325 1.551"/>
            <path class="fil0" d="m10.03 0.6716-0.07328 0.02932c0.04021 0.07598 0.425 0.6715 0.425 0.6741 0 0.1559-0.05207 0.3163-0.07371 0.4685-0.0185 0.1302-0.05744 0.3258-0.08749 0.4547 0.01584 0.06799 0.2393 0.3338 0.2931 0.3957l0.02932-0.01466 0.1653-0.9258 0.06914 0.1199 0.02321-0.00288 0.6211-1.321-0.4201 0.03614-0.2829 0.5987c-0.09405-0.0689-0.2751-0.4811-0.3664-0.5422-0.03433 8e-3 -0.04329 0.01466-0.08793 0.01466l-0.04432-0.00337z"/>
            <polygon class="fil1" points="1.629 1.535 1.512 1.551 1.614 1.551 1.614 1.58"/>
            <polygon class="fil0" points="7.945 1.566 7.945 1.536 7.915 1.551"/>

            <polygon class="fil0" points="0.5734 1.622 0.5299 1.639 0.5592 1.639 0.5592 1.668"/>
            <polygon class="fil0" points="6.509 1.697 6.509 1.653 6.479 1.683"/>
            <polygon class="fil1" points="0.8962 1.727 0.8962 1.697 0.8669 1.712"/>
            <polygon class="fil0" points="4.823 1.727 4.794 1.712 4.794 1.741"/>
            <polygon class="fil1" points="1.028 1.697 1.014 1.758 1.043 1.741"/>
            <polygon class="fil1" points="4.926 1.829 4.926 1.8 4.897 1.815"/>

            <polygon class="fil1" points="1.541 2.005 1.541 1.961 1.512 1.99"/>
            <path class="fil0" d="m7.945 1.536v0.02932l-0.0293-0.01466-0.1026-0.1172c-0.01284 0.1544-0.0713 0.3967-0.102 0.5574-0.02484 0.13-0.1032 0.44-0.1032 0.5563 0 0.02648 0.2753 0.3719 0.305 0.3946l0.01738-0.04293v-0.0293c0-0.05313 0.0589-0.3308 0.07385-0.4097 0.02193-0.1158 0.06991-0.3007 0.07269-0.4255l7.3e-4 -0.0315 0.02858 0.01684 0.07328 0.07328 0.0293-0.02932 0.2342-1.25-0.2928 0.01883-0.02974-8.2e-4 -0.05819 0.03012v0.0293c0 0.181-0.1172 0.4575-0.1172 0.6448z"/>
            <polygon class="fil1" points="8.106 2.02 8.077 2.003 8.077 2.034"/>
            <path class="fil0" d="m0.8962 1.697v0.0293l-0.0293-0.01466-0.02332-0.01346-0.08138 0.3815 0.1047 0.1302 0.0293-0.0293 0.04573-0.2952 0.03913 0.02622 0.1203-0.06807 0.02858-0.01684-0.01393 0.0315-0.02931 0.1905c-0.00399 0.02299 0.07292 0.1095 0.08793 0.1319l0.0293-0.02932 0.05542-0.3379 0.02233-0.06135-0.07867-0.1006-0.1603 0.08955-0.02857 0.01684 0.01391-0.0608 0.0437-0.2362-0.1289 0.01745-0.01737 0.04292v0.02931c0 0.04872-0.0247 0.0911-0.02932 0.1465z"/>
            <polygon class="fil0" points="8.208 2.108 8.208 2.064 8.179 2.093"/>
            <path class="fil0" d="m1.512 1.551 0.1172-0.01634-0.01458 0.04564v0.04396l-0.1307 0.0752zm0.04396 0.2638v0.05863c0 0.05517-0.00964 0.02771-0.01464 0.08792v0.04396l-0.02932-0.01466c-0.02345-0.03503-0.07326-0.06528-0.07326-0.1026 0-0.02666 0.09334-0.07529 0.1172-0.08792l0.1905-0.1172v-0.0293c0-0.06136 0.04207-0.1643 0.04368-0.2519l-0.3661 0.03207c-0.06624 0.00651-0.06859 0.1599-0.08247 0.2253-0.0179 0.08446-0.04275 0.1929-0.04942 0.273 0.02602 0.03886 0.1894 0.2345 0.2345 0.2345h0.07326c0.05146 0 0.05862-0.06503 0.05862-0.1172 0-0.05654 0.01465-0.0328 0.01465-0.1026 0-0.02871-0.07005-0.1052-0.08791-0.1319z"/>
            <path class="fil0" d="m6.597 2.547-0.0315 0.01393 0.01684-0.02857 0.07669-0.4243 0.09916 0.1166 0.02932-0.02932 0.1598-0.8062c0.03823 0.02496 0.2798 0.2865 0.2798 0.3226 0 0.06837-0.01466 0.01958-0.01466 0.08793v0.02931c-0.02279 0.04307-0.05862 0.1726-0.05862 0.2344v0.02932c-0.0415 0.07842-0.4605 0.3898-0.5568 0.4543zm-0.08792-0.8939v0.04396l-0.0293-0.01466-0.09985-0.1162-0.0174 0.04293v0.0293c0 0.09299-0.05621 0.261-0.0713 0.3536-0.02304 0.1414-0.03735 0.2376-0.06609 0.3735-0.02693 0.1275-0.05029 0.2456-0.06819 0.3714-0.01604 0.1129-0.06336 0.2529-0.07283 0.3668l0.1034 0.00551 0.263-0.03481h0.02932c0.06978 0 0.04603-0.01466 0.1026-0.01466h0.02932c0.02665-0.03981 0.3664-0.2932 0.4343-0.3424 0.05773-0.04178 0.4303-0.321 0.4303-0.361 0-0.1108 0.05786-0.2618 0.07327-0.381 0.01621-0.1254 0.07326-0.2567 0.07326-0.381 0-0.03379-0.5292-0.6213-0.5861-0.6594-0.02661 0.01319-0.01828 0.01162-0.05894 0.01047l-0.2634 0.01885-0.02899 8e-4 -0.04492 0.01372z"/>

            <path class="fil0" d="m0.5299 1.639 0.04357-0.01672-0.01426 0.04602-8e-4 0.04367-0.01563 0.04638-0.03946-0.03037-0.01738 0.0429v0.02931c0 0.03674-0.04766 0.246-0.05862 0.2931 0.01359 0.05828 0.0742 0.1324 0.1128 0.1605l0.04893-0.3235 0.05216 0.01528 0.06451-0.3212c0.04583-0.00381 0.05204-0.01464 0.1026-0.01464l0.05536-4.9e-4 0.01792-0.1314c-0.04116 0.00343-0.05442 0.00805-0.1031 0.01075l-0.1317 0.01514-0.217 0.01849-0.01717 0.1168z"/>
            <polygon class="fil0" points="1.204 2.196 1.204 2.152 1.175 2.181"/>
            <polygon class="fil1" points="5.981 2.196 5.952 2.181 5.952 2.21"/>
            <polygon class="fil1" points="0.8962 2.225 0.8962 2.181 0.8669 2.21"/>
            <polygon class="fil0" points="6.786 2.242 6.787 2.196 6.758 2.225"/>
            <path class="fil0" d="m5.073 2.313 7.2e-4 -0.06082 0.02858 0.01686c0.02239 0.03342 0.05969 0.09113 0.1026 0.1026 0.00227-0.102 0.06662-0.3778 0.09024-0.4959 0.0151-0.07545 0.02808-0.1578 0.04684-0.2462 0.01256-0.05928 0.02111-0.205 0.08273-0.2104l0.4392-0.03375-0.0638 0.3551c-0.07011-0.01914-0.05635-0.06932-0.1263-0.1162 0 0.09215-0.04275 0.2604-0.0607 0.3643-0.01352 0.07822-0.07119 0.2812-0.07119 0.3538 0 0.08-0.0293 0.08119-0.0293 0.1612 0 0.0773-0.05126 0.234-0.05862 0.3224l-0.4392 0.0337-0.04436-0.0044zm-0.1466-0.5129v0.02931l-0.0293-0.01466-0.07328-0.08792-0.0293 0.01466-0.2782 1.511 0.1314-0.01918 0.1762-0.01031 0.6007-0.0602 0.05897 0.00338 0.1907-0.01489 0.1007-0.01805 0.1772-0.9229v-0.0293l0.0293 0.01464 0.09874 0.1165 0.2383-1.274v-0.02931h-0.0293l-0.191 0.01063-0.1463 0.01611-0.8936 0.07583c-0.0039 0.1745-0.1172 0.4586-0.1172 0.6155 0 0.03958-0.00699 0.04042-0.01466 0.07327z"/>
            <polygon class="fil0" points="5.102 2.269 5.073 2.252 5.073 2.313"/>

            <polygon class="fil1" points="6.582 2.533 6.565 2.561 6.597 2.547"/>
            <path class="fil0" d="m2.801 1.663c-0.00107 0.07286-0.01422 0.05431-0.01422 0.1078v0.02931l-0.03585 0.1005-0.5208 0.3101c-2.1e-4 -0.07838 0.03022-0.1972 0.04608-0.2764 0.01102-0.05515 0.02044-0.2313 0.08579-0.2345zm0.3228 0.3715c0-0.08304 0.0627-0.2913 0.07311-0.3812 0.0153-0.1323 0.05877-0.2557 0.05877-0.3808-0.03863 0.01916 0.01207 0.01466-0.04396 0.01466h-0.01464l-0.9228 0.07722-0.04433-0.00396-0.2337 0.03427-0.139 0.7118c-0.06365-0.04559-0.04989-0.09576-0.1256-0.116-0.00241 0.1078-0.06837 0.4455-0.09682 0.5626-0.02929 0.1206-0.09367 0.4301-0.09367 0.5511 0 0.01596 0.2754 0.3594 0.3077 0.381 0.00295-0.1318 0.07328-0.3271 0.07328-0.425 0-0.08 0.02931-0.08117 0.02931-0.1612 0-0.08183 0.04396-0.1778 0.04396-0.2491 0-0.05052 0.01083-0.05673 0.01466-0.1026 0.03899 0.02612 0.1081 0.1319 0.1319 0.1319 0.00625 0 0.9016-0.5618 0.9818-0.6155z"/>
            <polygon class="fil0" points="10.54 2.711 10.54 2.679 10.51 2.694"/>
            <path class="fil0" d="m9.82 1.038c0-0.0997 0.05862-0.2219 0.05862-0.3517l-0.601 0.05708-0.5273 0.04615-0.1317 0.01576c-0.00349 0.1432-0.1009 0.5315-0.1345 0.6993-0.0301 0.1506-0.1296 0.5519-0.1296 0.691 0 0.00261 0.2751 0.3119 0.304 0.3407 0.04415 0.0441 0.2716 0.3234 0.3114 0.3187l0.1607-0.01862 0.2053-0.0149 0.1455-0.7581 0.1287 0.1506c0.00738-0.1279 0.07787-0.2763 0.07787-0.4141 0-0.02969-0.4004-0.4731-0.4396-0.4836-0.01002 0.1205-0.07482 0.4217-0.1077 0.5518-0.04432 0.1756-0.07153 0.3583-0.1122 0.5326l-0.283-0.3184c0.01686-0.1515 0.08801-0.3539 0.1068-0.4879 0.01973-0.1409 0.08555-0.3603 0.08829-0.4832l0.4831-0.03524c-0.00335 0.05177-0.01419 0.058-0.01419 0.1085 0 0.05932-0.02932 0.1114-0.02932 0.1905l0.4204-0.2423c0.00836-0.03796 0.0192-0.04417 0.0192-0.09469z"/>
            <path class="fil0" d="m4.237 1.536 0.1025-0.01641-0.01454 0.03107-0.03188 0.2047-0.5392 0.3235c0.00909-0.1144 0.08754-0.3478 0.08754-0.4402v-0.0293l0.01738-0.04293 0.1289-0.01744 0.02958 0.00175zm-0.8792 1.802 0.0293-0.04396 0.1542-0.8779c0.03607 0.01033 0.4027 0.412 0.4027 0.4529v0.0293c0 0.03524 0.27 0.3411 0.3077 0.3664 0.01154-0.04957 0.04396-0.1666 0.04396-0.2198v-0.02932l0.0301-0.05817-8e-4 -0.02975c0-0.04803 0.0293-0.1131 0.0293-0.1612 0-0.05597-0.3279-0.3375-0.3517-0.4396l0.641-0.3871 0.1528-0.7994-0.9105 0.07932-0.3226 0.0265c-0.0341-5.4e-4 -0.04433 0.00387-0.04433 0.04024 0 0.07329-0.04597 0.2572-0.06393 0.3464-0.02283 0.1133-0.04317 0.2214-0.06795 0.3277l-0.1122-0.1215-0.01884 0.04853-8.2e-4 0.02901c0 0.09592-0.06902 0.3976-0.08986 0.5256-0.02349 0.1442-0.06587 0.351-0.1006 0.5002 0.01806 0.07753 0.236 0.3104 0.2931 0.3956z"/>
            <polygon class="fil0" points="3.387 3.356 3.387 3.295 3.358 3.339"/>


</svg>
        </div>
      </div>
      <audio
          title="Backgroundmusic"
          ref="audio">
        <source src="/mainaudio.mp3" type="audio/mpeg"  title="Backgroundmusic">
      </audio>
    </div>
  </div>
</template>

<script setup>
import VTypical from 'vue-typical';
import {ref} from "vue";

const showScreenError = ref(false)
const stage = ref('start');
const pin = ref('');
const audio = ref(null);
const showMain = ref(false);
/*
const getRandomInt = function (max) {
  return Math.floor(Math.random() * max);
} */

const images = ref([
     //  'style' : 'top: ' + getRandomInt(window.innerHeight - 400) +'px; left: '+ getRandomInt(window.innerWidth - 600) +'px;',
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0001_birthday-1.jpg",'start' : 16000, 'end' : 16050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0002_birthday-2.jpg",'start' : 18000, 'end' : 18050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0003_party-1.jpg",'start' : 21000, 'end' : 21050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0004_party-2.jpg",'start' : 28000, 'end' : 28050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0005_party-3.jpg",'start' : 42000, 'end' : 42050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0006_party-4.jpg",'start' : 53000, 'end' : 53050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0007_party-5.jpg",'start' : 48000, 'end' : 48050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0008_rave-1.jpg",'start' : 23000, 'end' : 23050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0009_rave-2.jpg",'start' : 45000, 'end' : 45050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0010_rave-3.jpg",'start' : 62000, 'end' : 62050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0011_rave-4.jpg",'start' : 31000, 'end' : 31050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0012_rave-5.jpg",'start' : 39000, 'end' : 39050},
  {'show' : false, 'src' : "/images/prodigy-backgrounds_0013_rave-6.jpg",'start' : 10500, 'end' : 10550},

]);

const steps = [
  4000,'Welcome',
  4000,'Welcome Matt',
  5000,'Happy 40th Birthday',
  6000,'guess what', // 19
  4000,'we know what you are doing ',
  3000,'not now but', // 26
  4000,'on the 23rd November', // 30
  4000,'You\'re going to head out', //34
  4000,'at 18:00', //39
  5000,'to the Utilita arena', //41
  4000,'to go and see...']; // 45

const checkPin = function(){
  if(pin.value == '231123'){
    if(window.innerWidth > 1000){
      stage.value = 'headphones';
    }else{
      showScreenError.value = true;
      console.log(showScreenError)
    }

  }else{
    pin.value = '';
  }
}

const start = function(){
  audio.value.play();
  stage.value = 'show';
  setTimeout(() => {showMain.value = true}, 70000);

  images.value.forEach(function(value){
    setTimeout(() => {value.show = true}, value.start);
    setTimeout(() => {value.show = false}, value.end);
  })
}
</script>

<style>
#app {
  @apply w-full h-full;
  font-family: Courier, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFF;
}


.fil0 {animation: col 3s linear infinite;}
.fil1 {animation: col 3s linear infinite;}

@keyframes col {
  0%,71% {fill:orange}
  72% {fill:black}
  75%,100% {fill:orange}
}

.glitch{
  animation: glitch1 2.5s infinite;
}

/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }
  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  27% {
    transform: none;
    opacity: 1;
  }
  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }
  35% {
    transform: none;
    opacity: 1;
  }
  52% {
    transform: none;
    opacity: 1;
  }
  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }
  50% {
    transform: none;
    opacity: 1;
  }
  72% {
    transform: none;
    opacity: 1;
  }
  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }
  80% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.25;
  }
  72% {
    transform: none;
    opacity: 0.25;
  }
  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.25;
  }
  100% {
    transform: none;
    opacity: 0.25;
  }
}
</style>